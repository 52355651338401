<template>
    <app-loader></app-loader>
</template>

<script>
import { auth } from '@/services/auth.service';
import AppLoader from '@/components/common/AppLoader/AppLoader.vue';


export default {
  components: {
    AppLoader,
  },
  data() {

  },
  methods: {

    handleCallback() {
      ;
      auth.login();
    }

  },
  mounted() {
    this.handleCallback();
  }

};
</script>

<style></style>